import { IconDefaultProps } from '../icon-default.props';

const MustacheIcon = ({ fill = '#1A497F', ...props }: IconDefaultProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                d="M12.6602 8.59114C12.6602 8.59114 13.004 6.42542 16.102 7.14733C18.7892 7.77385 19.0841 11.1434 20.8606 11.3944C23.1733 11.7207 24.0343 8.96565 23.5133 12.7529C23.0948 15.8042 20.92 17 17.8229 17C14.7258 17 12.6602 14.1134 12.6602 14.1134C12.6602 14.1134 10.5954 17 7.4974 17C4.39937 17 2.23027 15.8042 1.80699 12.7529C1.28602 8.96565 2.14696 11.7207 4.46066 11.3944C6.23521 11.1434 6.53113 7.77385 9.21832 7.14733C12.3164 6.42542 12.6602 8.59114 12.6602 8.59114Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MustacheIcon;
