import { IconDefaultProps } from '../icon-default.props';

const MentalHealthIcon = ({ fill = '#1A497F', ...props }: IconDefaultProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                d="M6.89844 13.7148C7.75558 16.8006 11.1842 18.6863 14.2699 17.8291C16.1556 17.1434 17.6984 15.6006 18.2128 13.7148"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.32701 9.42746C8.09032 9.42746 7.89844 9.23558 7.89844 8.99888C7.89844 8.76219 8.09032 8.57031 8.32701 8.57031"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.32812 9.42746C8.56482 9.42746 8.7567 9.23558 8.7567 8.99888C8.7567 8.76219 8.56482 8.57031 8.32812 8.57031"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.0419 9.42746C16.8052 9.42746 16.6133 9.23558 16.6133 8.99888C16.6133 8.76219 16.8052 8.57031 17.0419 8.57031"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.0391 9.42746C17.2758 9.42746 17.4676 9.23558 17.4676 8.99888C17.4676 8.76219 17.2758 8.57031 17.0391 8.57031"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 23C18.5752 23 23.5 18.0752 23.5 12C23.5 5.92487 18.5752 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0752 6.42487 23 12.5 23Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MentalHealthIcon;
