import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BadgeStatus from 'ui-kit/bagde/badge-status';

import DrugInfoModal from 'display-components/birdi-discount/modal-contents/drug-info.component';

import { resetDrugDescription } from 'state/drug/drug.reducers';
import { drugDescriptionRoutine } from 'state/drug/drug.routines';
import { drugDescriptionSelector } from 'state/drug/drug.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { MedicineCardProps } from './medicine-card.props';
import './medicine-card.style.scss';

const MedicineCard = ({ name, description, dose, icon, ndc, gpi }: MedicineCardProps) => {
    // TODO: We're mocking the Amoxicilin NDC identifier, it is crucial to establish a reliable
    // source for accessing this data. Define if this gonna retrieve an API or getting the information
    // through Drupal via JSON api.
    const dispatch = useDispatch();
    const drugDescription = useSelector(drugDescriptionSelector);

    const drugDescriptionBody = useMemo(() => {
        const drugDescriptionHtml = document.createElement('html');
        drugDescriptionHtml.innerHTML = drugDescription;

        return drugDescriptionHtml.getElementsByTagName('body')[0];
    }, [drugDescription]);

    const handleMoreDrugInfo = () => {
        dispatch(resetDrugDescription());

        dispatch(
            drugDescriptionRoutine.trigger({
                gpi,
                ndc,
                onSuccess: () => {
                    dispatch(
                        openModalComponent({
                            title: `Amoxicilin`,
                            hasDefaultFooter: false,
                            hasCustomContent: true,
                            content: <DrugInfoModal description={drugDescriptionBody} />,
                            variation: 'large',
                            isCentered: true,
                            onClose: () => dispatch(closeModalComponent())
                        })
                    );
                }
            })
        );
    };

    return (
        <div
            className="medicine-card"
            onClick={handleMoreDrugInfo}
            role="button"
            tabIndex={0}
            onKeyPress={handleMoreDrugInfo}
        >
            <div className="medicine-card__content">
                <h3 className="medicine-card__name">{name}</h3>
                {description && <p className="medicine-card__description">{description}</p>}
                {icon && (
                    <div className="medicine-card__category">
                        <BadgeStatus icon={icon} variant="feature" label={icon.title || ''} />
                    </div>
                )}
            </div>
            {dose && (
                <div className="medicine-card__dose">
                    <span className="medicine-card__dose-text">{dose}</span>
                </div>
            )}
        </div>
    );
};

export default MedicineCard;
