import React, { CSSProperties, ReactElement } from 'react';

import './spinner-icon.styles.scss';

const SpinnerIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`spinner-animation ${className ?? ''}`} style={style}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.875 1.875C11.875 2.91055 11.0355 3.75 10 3.75C8.96445 3.75 8.125 2.91055 8.125 1.875C8.125 0.839453 8.96445 0 10 0C11.0355 0 11.875 0.839453 11.875 1.875ZM10 16.25C8.96445 16.25 8.125 17.0895 8.125 18.125C8.125 19.1605 8.96445 20 10 20C11.0355 20 11.875 19.1605 11.875 18.125C11.875 17.0895 11.0355 16.25 10 16.25ZM18.125 8.125C17.0895 8.125 16.25 8.96445 16.25 10C16.25 11.0355 17.0895 11.875 18.125 11.875C19.1605 11.875 20 11.0355 20 10C20 8.96445 19.1605 8.125 18.125 8.125ZM3.75 10C3.75 8.96445 2.91055 8.125 1.875 8.125C0.839453 8.125 0 8.96445 0 10C0 11.0355 0.839453 11.875 1.875 11.875C2.91055 11.875 3.75 11.0355 3.75 10ZM4.25477 13.8702C3.21922 13.8702 2.37977 14.7097 2.37977 15.7452C2.37977 16.7808 3.21922 17.6202 4.25477 17.6202C5.29031 17.6202 6.12977 16.7808 6.12977 15.7452C6.12977 14.7097 5.29027 13.8702 4.25477 13.8702ZM15.7452 13.8702C14.7097 13.8702 13.8702 14.7097 13.8702 15.7452C13.8702 16.7808 14.7097 17.6202 15.7452 17.6202C16.7808 17.6202 17.6202 16.7808 17.6202 15.7452C17.6202 14.7097 16.7808 13.8702 15.7452 13.8702ZM4.25477 2.37977C3.21922 2.37977 2.37977 3.21922 2.37977 4.25477C2.37977 5.29031 3.21922 6.12977 4.25477 6.12977C5.29031 6.12977 6.12977 5.29031 6.12977 4.25477C6.12977 3.21922 5.29027 2.37977 4.25477 2.37977Z"
                    fill="#828E9D"
                />
            </svg>
        </div>
    );
};

export default SpinnerIcon;
