import {
    forwardRef,
    ReactElement,
    RefObject,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import PillSearchIcon from 'ui-kit/icons/pill-search-icon/pill-search-icon';
import SpinnerIcon from 'ui-kit/icons/spinner-icon/spinner-icon';
import TextSetValue from 'ui-kit/text/textSetValue';

// State
import { getAutocompleteValues } from 'state/discount-card/discount-card.routines';
import {
    discountCardScriptSaveAutocompleteValuesSelector,
    discountCardScriptSaveIsAutocompleteLoadingSelector
} from 'state/discount-card/discount-card.selector';

// Styles
import './prescription-search.styles.scss';

interface PrescriptionSearchProps {
    onSearch?: (searchValue: string) => void;
    inputError?: string;
    isBusy?: boolean;
    withButton?: boolean;
    allowClearInputValue?: boolean;
    allowSubmitWithError?: boolean;
    allowSearchWithoutAutocomplete?: boolean;
}

const PrescriptionSearch = forwardRef(
    (
        {
            onSearch,
            inputError,
            isBusy,
            withButton = true,
            allowClearInputValue = true,
            allowSubmitWithError = false,
            allowSearchWithoutAutocomplete = false
        }: PrescriptionSearchProps,
        ref
    ): ReactElement => {
        const { t } = useTranslation();
        const [search, setSearch] = useState<string>('');
        const [dropdownIndex, setDropdownIndex] = useState<number>(0);
        const [selectedDrug, setSelectedDrug] = useState<string>('');
        const [menuActive, setMenuActive] = useState<boolean>(false);
        const [error, setError] = useState<string>('');

        const searchParams = new URLSearchParams(window.location.search);
        const drugNameQueryParam = searchParams.get('drugName');

        const searchRef = useRef(null);

        const dispatch = useDispatch();

        const autocompleteValues = useSelector(discountCardScriptSaveAutocompleteValuesSelector);
        const isAutocompleteLoading = useSelector(discountCardScriptSaveIsAutocompleteLoadingSelector);

        const getAutocompleteValuesLength = useCallback(() => autocompleteValues.length, [autocompleteValues]);

        useImperativeHandle(ref, () => ({
            triggerSearch: handleSearch
        }));

        const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            // Capitalize search to visually match autocomplete values
            const parseValue =
                value.length === 0
                    ? ''
                    : value.length > 1
                    ? value[0].toUpperCase() + value.substring(1).toLowerCase()
                    : value[0].toUpperCase();
            setSearch(parseValue);
            setDropdownIndex(0);
            setMenuActive(true);
        };

        const handleChangeSelectedDrug = useCallback((drug: string) => {
            setDropdownIndex(-1);
            setSelectedDrug(drug);
            setSearch(drug);
            setMenuActive(false);
        }, []);

        // Handle with search action submit
        const handleSearch = useCallback(() => {
            if (!onSearch) return;

            setMenuActive(false);

            if (search?.trim() === '') {
                setError(inputError!);
                // In cases where the form is controlled on another component,
                // submit is necessary to show the form's errors
                if (allowSubmitWithError) {
                    onSearch('');
                }
                return;
            }

            const hasAutocompleteValues = autocompleteValues?.length > 0;
            /**
             * In case the search fails or does not find the searched drug
             * proceed with the search term as is and the user to be redirected to the drug not found page
             */

            if (!hasAutocompleteValues) {
                onSearch(search);
                setError('');
                return;
            }
            /**
             * If the search text is partially written at the time of the search,
             * select the first option from the dropdown menu
             */

            if (allowSearchWithoutAutocomplete) {
                onSearch(search);
                setError('');
                return;
            }

            const value = dropdownIndex !== -1 ? autocompleteValues[0] : search;
            onSearch(value);
            setError('');
            if (allowClearInputValue) {
                setSelectedDrug('');
                setSearch('');
            }
        }, [search, autocompleteValues, dropdownIndex, setError, onSearch, inputError]);

        const onKeyDownHandler = useCallback(
            (e: React.KeyboardEvent<HTMLInputElement>) => {
                setMenuActive(true);

                if (e.key === 'ArrowDown') {
                    setDropdownIndex(
                        dropdownIndex < getAutocompleteValuesLength() - 1 ? dropdownIndex + 1 : dropdownIndex
                    );
                }

                if (e.key === 'ArrowUp') {
                    setDropdownIndex((prevState) => (prevState > 0 ? prevState - 1 : prevState));
                }

                if (e.key === 'Enter') {
                    const drug = autocompleteValues[dropdownIndex] || '';

                    if (search === selectedDrug && (!drug || drug === search)) {
                        handleSearch();
                        return;
                    }

                    handleChangeSelectedDrug(autocompleteValues[dropdownIndex] || '');
                }

                if (e.key === 'Escape') {
                    setMenuActive(false);
                }
            },
            [
                dropdownIndex,
                getAutocompleteValuesLength,
                autocompleteValues,
                search,
                selectedDrug,
                handleChangeSelectedDrug,
                handleSearch
            ]
        );

        useEffect(() => {
            if (search?.length === 0) {
                setMenuActive(false);
            }

            const delayedAPICall = setTimeout(() => {
                if (search?.trim() !== '' && search?.length > 0 && search !== selectedDrug) {
                    dispatch(
                        getAutocompleteValues.trigger({
                            search
                        })
                    );
                }
            }, 1000);

            return () => clearTimeout(delayedAPICall);
        }, [dispatch, search, selectedDrug]);

        useEffect(() => {
            // Manually focus the search component after autocomplete values render
            return () => (searchRef as RefObject<HTMLInputElement>).current?.focus();
        }, [autocompleteValues]);

        useEffect(() => {
            setSelectedDrug('');
            setSearch('');
        }, [drugNameQueryParam]);

        return (
            <div className="search-box">
                <TextSetValue
                    className="search-box__search-input"
                    name={'drugName'}
                    label={t('components.prescriptionSearch.input.label')}
                    type="text"
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDownHandler}
                    value={search}
                    errors={error}
                    touched={search === ''}
                    autocomplete="off"
                    disabled={isAutocompleteLoading || isBusy}
                    inputRef={searchRef}
                />
                {search && (
                    <ul className={`search-box__list ${menuActive ? 'hasBoxShadow' : ''}`} role={'listbox'}>
                        {isAutocompleteLoading && (
                            <li className={'search-box__list-spinner'}>
                                <SpinnerIcon />
                            </li>
                        )}
                        {menuActive &&
                            !isAutocompleteLoading &&
                            autocompleteValues &&
                            autocompleteValues.map(
                                (drug: string, i: number) =>
                                    drug.toLowerCase().startsWith(search.toLowerCase()) && (
                                        <li
                                            role={'option'}
                                            aria-selected={dropdownIndex === i ? 'true' : 'false'}
                                            className={`search-box__list-drug ${dropdownIndex === i ? 'active' : ''}`}
                                            key={i}
                                        >
                                            <PillSearchIcon />
                                            <Button
                                                variant="text"
                                                label={drug.replace(search, search ? `<strong>${search}</strong>` : '')}
                                                type="button"
                                                className="my-2 mx-md-2 d-block sm-full d-sm-inline text-dark"
                                                onClick={() => handleChangeSelectedDrug(drug)}
                                                dangerouslySetLabel
                                            />
                                        </li>
                                    )
                            )}
                    </ul>
                )}
                {withButton && (
                    <Button
                        onClick={handleSearch}
                        className="search-box__search-btn"
                        type="button"
                        label="Search"
                        searchIcon
                        disabled={isBusy || isAutocompleteLoading || !onSearch}
                    />
                )}
            </div>
        );
    }
);

export default PrescriptionSearch;
