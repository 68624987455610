import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { noop } from 'util/function';

import './icon-list.style.scss';

export interface Icon {
    id: string | number;
    title: string;
    icon?: string | ReactNode;
    subTitle?: string;
}

export interface IconListProps {
    list: Icon[];
    onChange?: (icon: Icon) => void;
    selectedIcon?: Icon;
}

export const IconList = ({ list, onChange, selectedIcon }: IconListProps) => {
    const handleChange = (icon: Icon) => {
        onChange?.(icon);
    };

    return (
        <div className="icon-list-component">
            {list.map((item, index) => (
                <div
                    key={index}
                    className={classNames('icon-list-component__item', {
                        'icon-list-component__item--selected': selectedIcon?.icon === item.icon
                    })}
                    onClick={() => item.icon && handleChange(item)}
                    onKeyDown={() => noop}
                    role="button"
                    tabIndex={0}
                >
                    <div className="icon-list-component__item__icon">
                        {typeof item.icon === 'string' ? (
                            <img src={item.icon} alt={item.title} />
                        ) : item.icon && typeof item.icon === 'function' ? (
                            React.createElement(item.icon)
                        ) : (
                            item.icon
                        )}
                    </div>
                    {item.subTitle && <p>{item.subTitle}</p>}
                    <p>{item.title}</p>
                </div>
            ))}
        </div>
    );
};
