import { IconDefaultProps } from '../icon-default.props';

const WomenBodyIcon = ({ fill = '#1A497F', ...props }: IconDefaultProps) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="pregnancy-vagina 1" clipPath="url(#clip0_24035_4414)">
                <path
                    id="Vector"
                    d="M5.25549 1.25C5.25549 1.25 6.75549 2.75 6.75549 7.25C6.75549 12.5 2.25549 13.25 2.25549 19.25C2.17766 20.8845 2.71255 22.4891 3.75549 23.75"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M18.7559 1.25C18.7559 1.25 17.2559 2.75 17.2559 7.25C17.2559 12.5 21.7559 13.25 21.7559 19.25C21.8337 20.8845 21.2988 22.4891 20.2559 23.75"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_3"
                    d="M12.0059 23.75V20.75"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_4"
                    d="M12.0065 20.7503L8.42552 17.0143C8.11049 16.7004 7.90231 16.2953 7.83051 15.8565C7.75871 15.4176 7.82694 14.9672 8.02552 14.5693C8.17568 14.2691 8.39501 14.0089 8.66545 13.81C8.93589 13.6112 9.2497 13.4794 9.58103 13.4256C9.91236 13.3718 10.2517 13.3975 10.5712 13.5005C10.8907 13.6036 11.1811 13.7811 11.4185 14.0183L12.0065 14.6003L12.5915 14.0153C12.8289 13.7778 13.1193 13.6002 13.4388 13.497C13.7583 13.3938 14.0977 13.3681 14.4291 13.4219C14.7605 13.4757 15.0744 13.6076 15.3448 13.8066C15.6153 14.0055 15.8345 14.2659 15.9845 14.5663C16.1831 14.9642 16.2513 15.4146 16.1795 15.8535C16.1077 16.2923 15.8996 16.6974 15.5845 17.0113L12.0065 20.7503Z"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_24035_4414">
                    <rect width="24" height="24" fill="white" transform="translate(0.00585938 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WomenBodyIcon;
