import BirdiSelectSearchByDrugMobileImg from 'assets/images/birdi-select-info-banner-search-by-drugs-mobile.png';
import BirdiSelectSearchByDrugImg from 'assets/images/birdi-select-info-banner-search-by-drugs.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import MentalHealthIcon from 'ui-kit/icons/mental-health/mental-health-icon';
import MustacheIcon from 'ui-kit/icons/mustache/mustache-icon';
import RxRefillsIcon from 'ui-kit/icons/rx-refills/rx-refills-icon';
import SkinCareIcon from 'ui-kit/icons/skin-care-icon/skin-care-icon';
import WomenBodyIcon from 'ui-kit/icons/women-body/women-body';

import { Icon } from 'display-components/icon-list/icon-list';
import MedicineCard from 'display-components/medicine-card/medicine-card';

import PrescriptionSearch from 'components/prescription-search';

import { BREAKPOINTS } from 'const/breakpoints';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './birdi-select-search-by-drug.style.scss';

export const BirdiSelectSearchByDrug = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const [background, setBackground] = useState<string>(BirdiSelectSearchByDrugImg);
    const [searchValue, setSearchValue] = useState<string>('');

    const randomIcons = [SkinCareIcon, MentalHealthIcon, MustacheIcon, RxRefillsIcon, WomenBodyIcon];

    const randomLength = Math.floor(Math.random() * 24) + 1;

    const medicines = Array.from({ length: randomLength }, (_, index) => ({
        name: `Medicine ${index + 1}`,
        description: `Description for medicine ${index + 1}`,
        dose: `${index + 1}00mg`,
        icon: {
            id: index,
            title: 'Medice Icon Title',
            icon: React.createElement(randomIcons[Math.floor(Math.random() * randomIcons.length)])
        },
        gpi: 65862001705,
        ndc: 65862001705
    }));

    const handleSearch = (searchValue: string) => {
        setSearchValue(searchValue);
        //here will be the logic to fetch the data from the backend
    };

    useEffect(() => {
        if (width <= BREAKPOINTS.md) {
            setBackground(BirdiSelectSearchByDrugMobileImg);
        } else {
            setBackground(BirdiSelectSearchByDrugImg);
        }
    }, [width]);

    return (
        <div className="birdi-select-search-by-drug">
            <div
                className="birdi-select-search-by-drug__container"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundPosition: background === BirdiSelectSearchByDrugImg ? 'right center' : 'right top',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'unset'
                }}
            >
                <div className="birdi-select-search-by-drug__search_box">
                    <div className="birdi-select-search-by-drug__search_box__title">
                        <h2>{t('pages.birdiSelect.searchByDrug.exploreBirdiFormulary')}</h2>
                        <h3>{t('pages.birdiSelect.searchByDrug.overDescription')}</h3>
                    </div>
                    <PrescriptionSearch
                        onSearch={(searchValue: string) => handleSearch(searchValue)}
                        allowClearInputValue={true}
                        isBusy={false}
                        inputError={t('components.prescriptionSearch.input.error')}
                        allowSubmitWithError
                        allowSearchWithoutAutocomplete
                    />
                </div>
            </div>
            {searchValue && (
                <>
                    <div>
                        <p>
                            {t('pages.birdiSelect.searchByDrug.searchResultsFor')} <strong>{searchValue}</strong>
                        </p>
                    </div>
                    <div className="birdi-select-search-by-drug__search_box__results">
                        {medicines.map((item, index) => (
                            <MedicineCard
                                key={index}
                                name={item.name}
                                description={item.description}
                                dose={item.dose}
                                icon={item.icon as Icon}
                                gpi={item.gpi}
                                ndc={item.ndc}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
