import { IconDefaultProps } from '../icon-default.props';

const TrhoatIcon = ({ fill = '#1A497F', ...props }: IconDefaultProps) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="Health Condition: Throat">
                <path
                    id="Vector"
                    d="M9.07602 22.5V19.8036C5.94271 19.8036 5.46134 19.8036 5.46134 14.6356H3.29138C2.67356 14.6356 2.2145 14.0952 2.36457 13.5302C5.29441 2.5 10.1533 2.5 13.6553 2.5C23.537 2.5 24.2601 13.7357 19.4397 16.8824V22.5M11.7686 18.6843L12.4504 18.0415M15.6256 18.9506L16.3075 19.5935M12.7329 22.3207L13.4147 21.6778"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};

export default TrhoatIcon;
