import { Icon } from 'display-components/icon-list/icon-list';
import MedicineCard from 'display-components/medicine-card/medicine-card';

import { BirdiSelectInfoBannerMedicationResults } from '../birdi-select-info-banner-medication-results/birdi-select-info-banner-medication-results';
import './birdi-select-browse-medications-results-by-icon.style.scss';

interface BirdiSelectBrowseMedicationsByIconProps {
    selectedIcon: Icon;
    onClick?: () => void;
}

export const BirdiSelectBrowseMedicationsByIcon = ({ selectedIcon }: BirdiSelectBrowseMedicationsByIconProps) => {
    const randomLength = Math.floor(Math.random() * 24) + 1;

    const medicines = Array.from({ length: randomLength }, (_, index) => ({
        name: `Medicine ${index + 1}`,
        description: `Description for medicine ${index + 1}`,
        dose: `${index + 1}00mg`,
        gpi: 65862001705,
        ndc: 65862001705
    }));

    return (
        <div className="birdi-select-browse-medications-by-icon">
            <BirdiSelectInfoBannerMedicationResults icon={selectedIcon} />
            <div className="birdi-select-browse-medications-by-icon__medications_results">
                {medicines.map((item, index) => (
                    <MedicineCard
                        key={index}
                        name={item.name}
                        description={item.description}
                        dose={item.dose}
                        ndc={item.ndc}
                        gpi={item.gpi}
                    />
                ))}
            </div>
        </div>
    );
};
