import { IconDefaultProps } from '../icon-default.props';

const RxRefillsIcon = ({ fill = '#1A497F', ...props }: IconDefaultProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M16.1263 10.3872C16.9542 9.60468 18.0546 9.17582 19.1937 9.19184C20.3328 9.20785 21.4207 9.66748 22.2263 10.473C23.0318 11.2785 23.4914 12.3665 23.5075 13.5056C23.5235 14.6447 23.0946 15.7451 22.312 16.5729L17.0401 21.8449C16.2169 22.6329 15.1186 23.0687 13.979 23.0594C12.8394 23.0502 11.7484 22.5968 10.938 21.7955C10.1275 20.9943 9.66167 19.9084 9.6395 18.769C9.61734 17.6296 10.0406 16.5265 10.8193 15.6943L16.1263 10.3872Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7383 12.7773L19.9241 18.963"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7164 6.71012C15.0601 3.42043 12.1564 0.941406 8.67377 0.941406C4.70749 0.941406 1.49219 4.15672 1.49219 8.123C1.49219 11.6097 3.97687 14.516 7.27218 15.1679"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6133 5.45589L15.718 6.7105L17.0076 3.80273"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.95617 5.30469V8.35895L6.46484 9.56449"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RxRefillsIcon;
