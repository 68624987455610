import { graphql } from 'gatsby';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageSection from 'ui-kit/page-section/page-section';

import HeroV2 from 'display-components/hero-v2/hero-v2.component';
import { HeroPropsV2 } from 'display-components/hero-v2/hero-v2.props';
import { Icon, IconList } from 'display-components/icon-list/icon-list';
import { ToggleTabList } from 'display-components/toggle-tab-list/toggle-tab-list';

import { BirdiSelectBrowseMedicationsByIcon } from 'components/birdi-select/birdi-select-browse-medications-results-by-icon/birdi-select-browse-medications-results-by-icon';
import { BirdiSelectSearchByDrug } from 'components/birdi-select/birdi-select-search-by-drug/birdi-select-search-by-drug';
import PageLayout from 'components/layouts/page/page.layout';

import { ConditionsIconsMock } from '../../util/birdi-select-conditions.mock';
import './birdi-select.style.scss';

const TABS = {
    EXPLORE_BY_CONDITION: 'Explore by condition',
    SEARCH_BY_DRUG: 'Search by drug'
};

const BirdiSelectPage = ({ data }: { data: GatsbyTypes.BirdiSelectPageDataQuery }) => {
    const { birdiSelectImage, medicineBottle3Image } = data;
    const { t } = useTranslation();
    const [iconSelected, setIconSelected] = useState<Icon>(ConditionsIconsMock[0]);

    // Futute, this props will be fetched from a DRUPAL
    //@important WE NEED A MAPPER TO TRANSFORM DRUPAL FETCHED DATA INTO THIS PROPS;

    const birdiSelectInfoBanner: HeroPropsV2 = {
        colorPalette: 'light',
        title: { text: t('pages.birdiSelect.heroComponent.title') },
        body: {
            text: (
                <>
                    {t('pages.birdiSelect.heroComponent.description_1')}{' '}
                    <strong>{t('pages.birdiSelect.heroComponent.description_2')} </strong>
                    {t('pages.birdiSelect.heroComponent.description_3')}{' '}
                    <strong>{t('pages.birdiSelect.heroComponent.description_4')} </strong>
                    {t('pages.birdiSelect.heroComponent.description_5')}
                </>
            )
        },
        eyeBrow: {
            text: t('pages.birdiSelect.heroComponent.eyeBrow')
        },
        image: {
            source: birdiSelectImage?.childImageSharp?.gatsbyImageData,
            position: 'bottom-right-breakout',
            alt: 'Birdi Select'
        }
    };

    const takeControlOfYourHealthBanner: HeroPropsV2 = {
        colorPalette: 'white',
        size: 'small',
        mainContentCols: 8,
        title: {
            text: t('pages.birdiSelect.heroComponent2.title')
        },
        body: {
            text: t('pages.birdiSelect.heroComponent2.description')
        },
        eyeBrow: {
            text: t('pages.birdiSelect.heroComponent2.eyeBrow'),
            color: 'light-blue'
        },
        image: {
            alt: 'medice-bottle',
            position: 'bottom-right',
            source: medicineBottle3Image?.childImageSharp?.gatsbyImageData
        },
        link: {
            href: '/get-started',
            label: t('pages.birdiSelect.heroComponent2.cta')
        }
    };

    const handleIconListClick = (icon: Icon) => {
        setIconSelected(icon);
    };

    const tabs: string[] = [TABS.EXPLORE_BY_CONDITION, TABS.SEARCH_BY_DRUG];

    return (
        <div className="birdi-select-page">
            <PageLayout metaData={{ nodeTitle: t('pages.birdiSelect.title') }} isShortFooter={true}>
                <div className="birdi-select-landing-page" id="birdi-select-landing-page">
                    <PageSection>
                        <HeroV2 {...birdiSelectInfoBanner} />
                    </PageSection>
                    <PageSection>
                        <div className="birdi-select-landing-page__browse-medication">
                            <div className="birdi-select-landing-page__browse-medication__description">
                                <h2>{t('pages.birdiSelect.browseMedicationsResults.browseMedications')}</h2>
                                <h3>{t('pages.birdiSelect.browseMedicationsResults.browseMedicationsDescription')}</h3>
                            </div>

                            <ToggleTabList tabs={tabs}>
                                <ToggleTabList.View id={TABS.EXPLORE_BY_CONDITION}>
                                    <>
                                        <div className="birdi-select-landing-page__icon-list-component">
                                            <IconList
                                                list={ConditionsIconsMock}
                                                onChange={(icon) => handleIconListClick(icon)}
                                                selectedIcon={iconSelected}
                                            />
                                        </div>
                                        <div className="birdi-select-landing-page__icon_information">
                                            <BirdiSelectBrowseMedicationsByIcon selectedIcon={iconSelected} />
                                        </div>
                                    </>
                                </ToggleTabList.View>
                                <ToggleTabList.View id={TABS.SEARCH_BY_DRUG}>
                                    <div className="birdi-select-landing-page__search-by-drug">
                                        <BirdiSelectSearchByDrug />
                                    </div>
                                </ToggleTabList.View>
                            </ToggleTabList>
                        </div>
                    </PageSection>
                    <PageSection>
                        <HeroV2 {...takeControlOfYourHealthBanner} />
                    </PageSection>
                </div>
            </PageLayout>
        </div>
    );
};

export default BirdiSelectPage;

// @MARK: Query
export const query = graphql`
    query BirdiSelectPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        birdiSelectImage: file(relativePath: { eq: "assets/images/birdi-select-monogram.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        medicineBottle3Image: file(relativePath: { eq: "assets/images/medicine-bottle-3.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }
    }
`;
