import React from 'react';

import MentalHealthIcon from 'ui-kit/icons/mental-health/mental-health-icon';
import MustacheIcon from 'ui-kit/icons/mustache/mustache-icon';
import RxRefillsIcon from 'ui-kit/icons/rx-refills/rx-refills-icon';
import SkinCareIcon from 'ui-kit/icons/skin-care-icon/skin-care-icon';
import TrhoatIcon from 'ui-kit/icons/throat/trhoat-icon';
import WomenBodyIcon from 'ui-kit/icons/women-body/women-body';

import { Icon } from 'display-components/icon-list/icon-list';

export const ConditionsIconsMock: Icon[] = [
    {
        id: 1,
        icon: React.createElement(WomenBodyIcon),
        title: "Women's Health"
    },
    {
        id: 2,
        icon: React.createElement(TrhoatIcon),
        title: 'Urgent Care'
    },
    {
        id: 3,
        icon: React.createElement(MustacheIcon),
        title: "Men's Health"
    },
    {
        id: 4,
        icon: React.createElement(RxRefillsIcon),
        title: 'Rx Refills'
    },
    {
        id: 5,
        icon: React.createElement(SkinCareIcon),
        title: 'Skin Care'
    },
    {
        id: 6,
        icon: React.createElement(MentalHealthIcon),
        title: 'Mental Health'
    }
];
